<template>
    <div
        class="color-swatch-container"
        :class="[{ 'color-swatch-container-disabled': disabled }, themeClass]"
    >
        <div
            v-if="colorValue"
            class="color-swatch"
            :class="{ 'color-swatch-border': !disableBorder }"
            :style="{
                ...colorValue,
                width: `${width}px`,
                height: `${height}px`,
                borderRadius: `${!disableBorderRadius ? borderRadius : 'inherit'}`
            }"
        ></div>
    </div>
</template>

<script lang="ts" setup>
    import { useCurrentTheme, useColorSwatches } from '~/composables';
    import type { CssStyleObject } from '~/types';

    const props = defineProps({
        color: {
            type: [String, Array] as PropType<Nilable<string | string[]>>,
            required: true
        },
        width: {
            type: Number as PropType<number>,
            default: 24
        },
        height: {
            type: Number as PropType<number>,
            default: 24
        },
        disabled: {
            type: Boolean as PropType<Nilable<boolean>>,
            default: false
        },
        disableBorder: {
            type: Boolean as PropType<Nilable<boolean>>,
            default: false
        },
        disableBorderRadius: {
            type: Boolean as PropType<Nilable<boolean>>,
            default: false
        },
        borderRadius: {
            type: String as PropType<string>,
            default: '4px'
        }
    });
    
    const { processColor } = useColorSwatches();
    const colorValue = ref<Nullable<CssStyleObject>>(processColor(props.color) || null);
    const { themeClass } = useCurrentTheme();

    const unWatchColorProp = watch(
        () => props.color,
        (newColor: string | string[] | unknown) => {
            const cssProp = processColor(newColor);

            if (cssProp) {
                colorValue.value = cssProp;
            }
        }
    );

    onBeforeUnmount(() => {
        unWatchColorProp();
    });
</script>

<style lang="scss">
.color-swatch-container {
    display: inline-block;
    position: relative;

    .color-swatch {
        position: relative;

        &-border {
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                margin: auto;
                top: 0;
                left: 0;
                z-index: 1;
                border: 1px solid #000;
                border-radius: v-bind(borderRadius);
                bottom: 0;
                right: 0;
                // opacity: 0.1;
                // opacity: 0.5;
                filter: invert(0.5) opacity(0.5);
            }
        }
    }

    &.theme--dark {
        .color-swatch {
            &-border {
                &:after {
                    border: 1px solid #fff;
                }
            }
        }
    }

    &-disabled {
        &:after {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            right: 3px;
            bottom: 3px;
            margin: auto;
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><line x1="0" y1="0" x2="100%" y2="100%" stroke="black" stroke-width="3"/><line x1="100%" y1="0" x2="0" y2="100%" stroke="black" stroke-width="3"/></svg>');
            filter: invert(0.5);
            mix-blend-mode: screen;
        }
    }
}
</style>
